import type { FetchOptions, FetchResponse } from 'ofetch';
import ApiError from '@/utils/api-error';

export default class BaseService {
    async get<T = any>(url: string, options?: FetchOptions): Promise<FetchResponse<T>> {
        return await this.request<T>('GET', url, options);
    }

    async post<T = any>(url: string, body?: any, options?: FetchOptions): Promise<FetchResponse<T>> {
        return await this.request<T>('POST', url, { ...options, body });
    }

    async put<T = any>(url: string, body?: any, options?: FetchOptions): Promise<FetchResponse<T>> {
        return await this.request<T>('PUT', url, { ...options, body });
    }

    async delete<T = any>(url: string, options?: FetchOptions): Promise<FetchResponse<T>> {
        return await this.request<T>('DELETE', url, options);
    }

    private async request<T = any>(method: string, url: string, options?: FetchOptions): Promise<FetchResponse<T>> {
        try {
            return await useNuxtApp().$api(url, { ...options, method });
        }
        catch (error) {
            throw new ApiError(error);
        }
    }
}